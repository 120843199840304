.mg--8 {
  margin-top: -8px;
}

.mg--5 {
  margin-top: -5px !important;
}

.mg-r-8 {
  margin-right: 8px;
}

.mg-r-15 {
  margin-right: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.m-bottom {
  margin-bottom: 10px;
}

.link-code {
  font-weight: 450;
}

.link-rewash-code {
  font-weight: 450;
  color: #df07ce !important;
}

.rounded-pill-div {
  border-radius: 20px;
  padding: 2px 8px;
  display: inline-block;
  border-width: 5px;
}

.cancel {
  background-color: #e7e9ea;
  color: #9ca4a8;
  border: 1px solid #9ca4a8;
}

.waiting {
  background-color: rgb(255, 244, 222);
  color: #f57f17;
  border: 1px solid rgb(249, 177, 21, 0.3);
}

.deactive {
  background-color: rgb(255, 170, 170);
  color: rgb(255, 0, 0);
  border: 1px solid rgb(255, 0, 0, 0.5);
}

.done {
  background-color: rgb(191, 241, 223, 0.3);
  color: #0fb365;
  border: 1px solid #94e8ca;
}

.doing {
  background-color: #e5fafb;
  color: rgb(20, 155, 213);
  border: 1px solid rgb(20, 155, 213, 0.3);
}

.received {
  background-color: rgba(235, 175, 175, 0.3);
  color: rgb(229, 83, 83);
  border: 1px solid rgb(229, 83, 83, 0.3);
}

.center {
  margin: auto;
  display: block;
  text-align: center;
}

.center-l {
  margin: auto;
  display: block;
  text-align: left;
}

.margin-top-10 {
  margin-top: 10px;
}

.upload-div {
  border: 1px dotted black;
  height: 30px;
  width: 100px;
  border-radius: 5px;
}

.title {
  font-size: 16px;
  color: #0179ff;
  font-weight: 400;
}

.title-sum {
  color: black !important;
  font-weight: 450 !important;
  font-size: 13px !important;
}

.color-info {
  color: #0179ff !important;
}

.line-div {
  background-color: #ced2d8;
  height: 1px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 10px;
}

.weight {
  background-color: white;
  color: black;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px;
  padding: 2px 8px;
  display: inline-block;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.sweet-loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* background: gray; */
  /* opacity: -0.5; */
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.font-size-16 {
  font-size: 17px;
  color: black;
  font-weight: 450 !important;
}

/*css date time*/
/*.MuiOutlinedInput-root{*/
/*  height: 2.35rem;*/
/*  font-size: 14px;*/
/*}*/

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 1.2rem;
}

/* .active-text {
  color: rgb(2, 158, 77);
  font-weight: 450;
} */

/* .lock-text {
  color: rgb(255, 0, 0);
  font-weight: 450;
} */
.time {
  color: black !important;
  font-size: 12px;
}

.gradient {
  background: rgb(4, 93, 140);
  background: linear-gradient(45deg,
      rgba(4, 93, 140, 1) 0%,
      rgba(4, 94, 139, 1) 18%,
      rgba(3, 119, 115, 1) 58%,
      rgba(2, 158, 77, 1) 84%);
}

.w-22 {
  width: 22%;
}

.w-162px {
  width: 162px;
}

.w-170px {
  width: 170px;
}

.w-80px {
  width: 80px;
}

.margin-t-5 {
  margin-top: 0.5rem;
}

.margin-l-5 {
  margin-left: 0.5rem;
}

.margin-r-5 {
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}

.form-check-input {
  position: inherit !important;
  margin-top: -0.5rem !important;
  margin-left: 0rem !important;
  margin-right: 4px;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.title-input {
  color: black;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-black {
  color: black;
  font-size: 14px;
}

.margin-t-10 {
  margin-top: 10px;
}

.margin-t--5 {
  margin-top: -15px;
}

/*Css tooltip*/
.tooltip1 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip1 .tooltiptext1 {
  width: 100%;
  visibility: hidden;
  background-color: #6d6d6d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0 0;
  position: absolute;
  z-index: 100;
  line-height: 5px;
  font-size: 10px;
  left: 100px;
  top: -10px;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

/*end css tooltip*/

.cell-error .dsg-input {
  color: #d74242;
  font-weight: bold;
}

.text-error {
  color: #d74242;
  font-weight: bold;
}

.row-highlight {
  color: #3a76fc;
}

.dsg-cell-disabled {
  background-color: rgb(86 86 86 / 5%) !important;
}

/*style react table*/
.cell-table-highest {
  color: red !important;
  /* animation: flash 1s ease-in-out infinite; */
}

.hyper-link-tag {
  cursor: pointer;
  text-decoration: underline;
  color: #321fdb;
  font-weight: bold;
  transition: 0.5s;
}

.cell-table-highest,
.cell-table-highest .hyper-link-tag {
  /* color: #321fdb; */
  color: red;
  transition: 0.5s;
  /* animation: flash 1s ease-in-out infinite; */
}

@keyframes flash {
  from {
    color: red;
  }

  to {
    color: #321fdb;
  }
}

.cell-table-high {
  background-color: #f7dcdc !important;
}

.cell-table-high .hyper-link-tag,
.cell-table-highest .hyper-link-tag {
  /* color: red !important; */
}

.drop_down_menu {
  background-color: white;
}

.drop_down_menu .unSeen {
  background-color: white;
  color: black;
  position: relative;
  width: 400px;
  padding-right: 5%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  line-height: 25px;
  overflow: hidden;
}

.drop_down_menu .unSeen:active,
.drop_down_menu .seen:active {
  background-color: transparent;
  color: black;
}

.drop_down_menu .unSeen:hover,
.drop_down_menu .seen:hover {}

.drop_down_menu .unSeen::after {
  content: " ";
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: red;
  top: 50%;
  left: 95%;
  transform: translate(0, -50%);
  border-radius: 10px;
}

.drop_down_menu .seen {
  background-color: transparent;
  color: gray;
  width: 400px;
  padding-right: 5%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  line-height: 25px;
}

/* width */
.drop_down_menu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.drop_down_menu::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.drop_down_menu::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
.drop_down_menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* custom input */
.float-label {
  position: relative;
  margin-bottom: 15px;
  height: 37.68px;
}

.float-label input {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  height: 37.68px;
  padding-left: 14px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  outline: none;
  font-family: "Comfortaa", "Helvetica", "Arial", sans-serif;
  /* font-family: "Maven Pro", sans-serif; */
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  font-size: 14.5px;
}

.float-label input:focus {
  border: 2px solid rgb(69, 128, 230) !important;
}

.float-label input:hover {
  border: 1px solid black;
}

.float-label .react-datepicker-popper {
  z-index: 9999;
}

.label {
  font-size: 0.7rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* font-family: "Maven Pro", sans-serif; */
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  position: absolute;
  pointer-events: none;
  left: 9px;
  top: -9px;
  background-color: white;
  padding: 0px 5px;
  color: black;
  transition: 0.2s ease all;
}

.label-float {
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
}

.border-left {
  border-left: 1px solid #ede6e6;
}

.sticky-left {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}

.thumbs .thumb img {
  width: 100% !important;
}

.tableHeigthMUI {
  height: 50vh;
}

/*hide scroll x react table*/
.ReactTable .rt-tbody {
  overflow-x: hidden;
}

/*overide css react table when fixed right columns*/
.rthfc .rt-th.rthfc-th-fixed-right-first,
.rthfc .rt-td.rthfc-td-fixed-right-first {
  border-left: solid 1px rgba(0, 0, 0, 0.02);
}

.center-menu {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 14.5px;
}

.relative-positon {
  position: relative;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.w100 {
  width: 100%;
}

.w40 {
  width: 40%;
}

.w-20 {
  width: 20%;
}

.dropdownShowHide {
  position: relative;
  display: inline-block;
}

.dropdownShowHide-contentShowHide {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 10;
  transform: translateX(-100%);
  left: 100%;
}

.dropdownShowHide-contentShowHide.show {
  display: block;
}

.normal {
  margin: 10px;
}

.img-icon {
  width: 20px;
  margin-right: 5px;
}

.text-icon {
  font-size: 14px;
}

.icon-box {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background: #e6efe9;
  border-radius: 5px;
  width: fit-content;
  margin-right: 15px;
  margin-bottom: 10px;
  position: relative;
}

.img-show {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  height: 140px;
}

.cursor-pointer {
  cursor: pointer;
}

.align-item-center {
  align-items: center;
}

@media screen and (max-width: 870px) {
  .w-20 {
    width: fit-content;
  }
}

.margin-lr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.title-bold-notif {
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  color: #0179ff;
}

.hover-read-all:hover {
  color: #0179ff;
}

.title-larger-notif {
  font-weight: 400;
  font-size: 18px;
  color: #0179ff;
}

.notification-content {
  width: 385px;
  padding: 0 5px;
}

.notification-content:hover {
  background-color: #f5f5f5;
}

.margin-10-left {
  margin-left: 10px;
}

.font-notif-content {
  font-size: 13px;
}

.margin-10 {
  margin: 10px;
}

.button__badge {
  border-radius: 5px;
  background: #f00;
  width: 7px;
  height: 7px;
  /* position: absolute; */
  top: 5px;
  right: 5px;
}

.delete-file-box {
  position: absolute;
  top: -5px;
  right: -8px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #e9d6d6;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fontWeight500 {
  font-weight: 600;
}

.h-100 {
  height: 100%;
}

.override-icon-button {
  border-radius: 4px !important;
  padding: 0 10px !important;
  height: 2.35rem !important;
}

.dsg-container {
  /* overflow: hidden !important; */
}

.right {
  margin-left: auto;
  margin-right: 0;
}

/*custom input type text data sheet*/
.align-right-text .dsg-input {
  text-align: right;
}

/*cusom header sub table*/
.ReactTable.subTable .rt-thead .rt-tr {
  padding-right: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactTable.schedule-delivery .rt-thead .rt-tr {
  padding-right: 0px !important;
}

.ReactTable.subTable .rt-thead .rt-resizable-header-content {
  padding: 0 !important;
}

.status-waitting .dsg-input {
  color: orange !important;
  font-weight: 450;
}

.status-processing .dsg-input {
  color: blue !important;
  font-weight: 450;
}

.status-done .dsg-input {
  color: rgb(2, 158, 77) !important;
  font-weight: 450;
}

.status-cancel .dsg-input {
  color: black !important;
  font-weight: 450;
}

.weigth-box {
  width: 250px;
  font-size: 35px;
  background: #373c3a;
  color: #2fbf15;
  padding: 10px 30px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  box-sizing: border-box;
}

.ReactTable.hide-padding-header .rt-thead .rt-tr {
  padding-right: 0 !important;
}

.ReactTable.subTable .rt-thead .rt-resizable-header-content {
  padding: 0 !important;
}

.required-column-sheet {
  display: flex;
  align-items: center;
}

.required-column-sheet::before {
  content: "*";
  margin-left: 2px;
  /* Adjust the spacing as needed */
  color: red;
  /* Customize the color */
  order: 2;
}

.ReactTable.history-table .rt-tr {
  padding-right: 0 !important;
}

.ReactTable.history-table .rt-tr-group {
  padding-top: 5px;
  padding-bottom: 5px;
}

.numberDifference-greater-than-0 .dsg-input {
  color: #4A86E8;
}

.numberDifference-less-than-0 .dsg-input {
  color: #9B0707;
}

.footer-data-sheet {
  border-top: 1px solid #e8ebed;
  border-bottom: 1px solid #e8ebed;
  padding: 5px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  color: #047004;
}

.footer-data-sheet:first-child {
  border-left: 1px solid #e8ebed;
}

.footer-data-sheet:last-child {
  border-right: 1px solid #e8ebed;
}

.footer-sheet .dsg-container {
  height: 40px !important;
  overflow-y: hidden;
}

.footer-sheet .dsg-row-header {
  display: none;
}

.footer-sheet .dsg-row {
  top: 0px;
}

.footer-sheet .dsg-cell.dsg-cell-gutter:first-child {
  color: transparent;
}

.footer-sheet .dsg-row {
  top: 0px !important;
}

.footer-sheet .dsg-scrollable-view-container {
  display: none;
}

.download-img-button {
  position: absolute;
  /* bottom: 23px; */
  cursor: pointer;
  right: 1px;
  /* background: #141d8f; */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-product .rt-table .rt-tbody .rt-td {
  padding: 0
}

.table-product-height-35 .rt-table .rt-tbody .rt-td {
  height: 40px
}

.capitalize-first {
  text-transform: capitalize;
}

.MuiModal-root {
  z-index: 800 !important;
}

.c-header.c-header-fixed {
  z-index: 700;
}

.c-sidebar-fixed {
  z-index: 720 !important;
}

.c-sidebar-backdrop {
  z-index: 700 !important;
}

.ant-image-preview-root .ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.9);
}

.center-cell input {
  text-align: center;
}

.truncate-cell {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.first-column {
  background-color: #4479C5 !important;
  color: white !important;
  padding: 16px;
  padding: 0 16px;
}
