@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400&display=swap");
body {
  // font-family: 'Maven Pro', sans-serif;
}
button {
  &:focus-visible {
    border: none;
    outline: none;
  }
}
.bg-login {
  background-image: linear-gradient(
    132.6deg,
    rgba(71, 139, 214, 1) 23.3%,
    rgba(37, 216, 211, 1) 84.7%
  );
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  input {
    color: black !important;
  }
  .Mui-focused {
    color: black !important;
  }
  .Mui-focused.MuiInput-root::after {
    border-bottom: 2px solid black;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(100%);
  }
  .customButton {
    &:focus {
      box-shadow: none;
    }
  }
}
#print {
  th,
  td {
    color: black;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .wSTTT {
    width: 4%;
  }
  .w-30{
    width: 30%;
  }
  .wSLT {
    width: 10%;
  }
  .wNoteT {
    width: 31%;
  }
  .wItemT {
    width: 35%;
  }
  .wSTTDB {
    width: 4%;
  }
  .wSLDB {
    width: 13%;
  }
  .wNoteDB {
    width: 35%;
  }
  .wItemDB {
    width: 30%;
  }
  .fontSize {
    font-size: 1.2rem;
  }
  .table th,
  .table td {
    padding: 0;
  }
}
.ReactTable {
  .rt-thead .rt-tr {
    padding-right: 10px;
  }

  .rt-tbody {
    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      display: absolute;
      right: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
    }
  }
}
#root,
body {
  &::-webkit-scrollbar {
    width: 0;
    display: absolute;
  }
}

.theLayout {
  .theHeader {
    .rightContent {
      li::marker {
        display: none;
        content: "";
      }
    }
  }
  #theNavbar {
    .nav-link {
      color: white;
    }
    .dropDownItem {
      &:focus-visible {
        outline: none;
      }
      &:active {
        background-color: white;
      }
      &:hover {
        background-color: #478bd6;
        .linkNavbar {
          color: white;
        }
      }
      .linkNavbar {
        color: black;
        text-decoration: none;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
.ReactTable .rt-tr.-odd {
  background: white;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  padding: 5px;
}
@page {
  margin: 2rem 2rem 2rem 2rem !important;
}
.note {
  div:first-child,
  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-1e1tib-MuiInputBase-root-MuiOutlinedInput-root {
    height: 100%;
    background-color: white;
  }
}
.note-w {
  div:first-child,
  .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .css-1e1tib-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: white;
  }
}
.labelColor {
  color: black !important;
  font-size: 14.6px;
  font-weight: 550;
}
.MuiBox-root.css-1kt0o6l {
  background-color: white;
}
.bg-white {
  background-color: white;
}
// .ReactTable .rt-tr.-odd {
//   background: rgba(0, 0, 0, 0.02);
// }
.ReactTable .rt-thead .rt-resizable-header-content {
  padding: 5px;
}

#wrapperWashing {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

// VARIABLES (Have fun) ―――――――――――――――――――――――――

// Machine

$WM-width: 300px;
$WM-height: $WM-width + $WM-width / 3.4;
$WM-borderRadius: 15px;
$WM-topHeight: 70px;
$WM-bottomHeight: 30px;
$WM-roationSpeed: 0.6s; // If changed, need to be updated in the JS as well

// Colors

$WM-mainColor: #ececf4; // White
$WM-secondaryColor: #c4c4d2; // Light grey
$WM-tertiaryColor: #80819c; // Dark grey
$WM-tubBackground: #453e57;

// Effects

$WM-insetShadowColor: rgba($WM-secondaryColor, 0.3);
$WM-insetShadowSize: 15px;

// Controls

$WM-controlsRatio: 2.2; // Higher numbers produce smaller size
$WM-screenTextSize: 1em;
$WM-screenTextColor: lightgreen;

// Door

$WM-doorRatio: 1.5; // Higher numbers produce smaller size
$WM-doorBorderSize: 25px;

// WASHING-MACHINE ―――――――――――――――――――――――――

#washingMachine {
  background: $WM-mainColor;
  width: $WM-width;
  height: $WM-height;
  border-radius: $WM-borderRadius;
  border-top: 15px solid $WM-tertiaryColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset -#{$WM-insetShadowSize} -#{$WM-insetShadowSize} 0 0
    $WM-insetShadowColor;
  padding: ($WM-topHeight + 15px) 0 $WM-bottomHeight + 15px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border: 4px solid transparent;
    width: 150%;
  }

  &:before {
    border-bottom-color: $WM-secondaryColor;
    height: $WM-topHeight;
    top: 0;
    border-radius: 50%;
    box-shadow: inset 0 -#{$WM-insetShadowSize} 0 0 $WM-insetShadowColor;
  }

  &:after {
    border-top-color: $WM-secondaryColor;
    height: $WM-bottomHeight;
    bottom: 0;
  }

  #controls {
    $WM-roundButtonSize: $WM-topHeight / $WM-controlsRatio;
    $WM-elementsWidth: $WM-width / 5;
    $WM-shift: $WM-insetShadowSize + 15px;

    // Screen

    top: ($WM-topHeight - $WM-roundButtonSize) / 2;
    text-align: center;
    right: $WM-shift;
    background: #242527;
    color: $WM-screenTextColor;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: $WM-screenTextSize;
    justify-content: center;

    &,
    &:before,
    &:after {
      position: absolute;
    }

    &,
    &:after {
      height: $WM-roundButtonSize - ($WM-roundButtonSize / 4);
      min-width: $WM-elementsWidth;
    }

    &,
    &:before {
      display: flex;
      align-items: center;
      transition: all 0.2s cubic-bezier(0.56, 0.35, 0, 1.35);
    }

    // Round button

    &:before {
      content: "━━";
      font-size: 14px;
      color: $WM-tertiaryColor;
      box-shadow: inset 0px -#{$WM-insetShadowSize} 0px 0px $WM-insetShadowColor;
      height: $WM-roundButtonSize;
      width: $WM-roundButtonSize;
      border: 3px solid $WM-secondaryColor;
      border-radius: 100%;
      transform: rotate(0deg);
      left: calc(100% + #{$WM-shift - $WM-width / 2 - $WM-roundButtonSize / 2});
    }

    // Washing powder compartment

    &:after {
      content: "";
      left: calc(100% + #{$WM-shift - $WM-width + $WM-shift});
      background: $WM-insetShadowColor;
      border-radius: 3px 3px 10px 10px;
      border: 3px solid $WM-secondaryColor;
      box-shadow: inset 0 10px 0 0 $WM-secondaryColor;
    }
  }

  // Reused values ――

  $WM-tubBaseShadow: 0 0 0 7px $WM-insetShadowColor; // Light grey

  @function doorShadows($inside: transparent, $outside: transparent) {
    @return inset 5px 10px 0 0 $inside, 5px -1px 0 0 $outside;
  }

  // ――

  #door,
  #tub {
    transition: all 0.2s ease-in-out;
  }

  #door {
    width: $WM-width / $WM-doorRatio;
    height: $WM-width / $WM-doorRatio;
    border: $WM-doorBorderSize solid $WM-tertiaryColor;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    box-shadow: doorShadows(rgba(black, 0.1));
    transform-origin: 0% 0%;
    transform: rotateY(0deg);

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    // Door handle

    &:after {
      background: darken($WM-tertiaryColor, 10%);
      height: 50%;
      width: 17%;
      right: 0;
      border-radius: 40% 30% 30% 40% / 50% 45% 45% 50%;
      transition: all 0.2s ease-in-out;
      z-index: 3;
    }

    // Reflection

    &:before {
      width: 60%;
      height: 60%;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
      border: 5px solid transparent;
      border-left: 15px solid white;
      transform: translateY(-50%) rotate(45deg);
      opacity: 0.2;
      box-shadow: inset -10px 10px 0 0 rgba(white, 0.2);
      border-radius: 40% 30% 30% 40% / 50%;
      z-index: 2;
    }
  }

  #tub {
    width: $WM-width / $WM-doorRatio - $WM-doorBorderSize;
    height: $WM-width / $WM-doorRatio - $WM-doorBorderSize;
    background: $WM-tubBackground;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    z-index: 0;
    box-shadow: $WM-tubBaseShadow;

    .clothes {
      position: absolute;
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }

    .clothes:nth-child(1) {
      background: linear-gradient(#48873e, #7b9f17);
      width: 70%;
      height: 25%;
      bottom: 10%;
      right: -20%;
      transform: rotate(-20deg);
      border-radius: 49% 38% 65% 8% / 30% 12% 51% 36%;
    }

    .clothes:nth-child(2) {
      background: linear-gradient(#c22323, #e15050);
      width: 100%;
      height: 20%;
      border-radius: 64% 40% 27% 47% / 63% 63% 32% 36%;
      bottom: 0;
    }

    .clothes:nth-child(3) {
      background: linear-gradient(#5a5589, #4f46b4);
      width: 70%;
      height: 25%;
      transform: rotate(40deg);
      border-radius: 41% 38% 71% 8% / 51% 36% 51% 24%;
      bottom: 0;
      left: -10%;
    }
  }

  // ACTIONS

  &.isWashing {
    animation-name: shake;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    #controls:before {
      transform: rotate(45deg);
    }

    #tub {
      animation-name: rotate;
      animation-duration: $WM-roationSpeed;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.isFilled #tub .clothes {
    opacity: 1;
  }
}

// ANIMATIONS ―――――――――――――――――――――――――

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
  25% {
    transform: translate(-1.5px, 1.5px) rotate(0.5deg);
  }
  50% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// PLAYGROUND ―――――――――――――――――――――――――

#playground {
  margin-top: 25px;
  width: $WM-width;
  display: flex;

  button {
    flex: 1;
    background: #ececf4;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 7px 10px;
    box-shadow: -1px 2px 0 0 rgba(black, 0.2);
    transition: background 0.2s ease-in-out;
    font: inherit;
    font-size: 0.8em;

    &#opening {
      margin: 0 15px;
    }

    &:hover:not(:disabled) {
      background: white;
      cursor: pointer;
    }

    &::-moz-focus-inner {
      border: 0;
    }
    &:active:not(:disabled) {
      box-shadow: 0px 1px 0 0 rgba(black, 0.2);
    }
  }
}

//css filter model
// .filter-model {
// 	display: block;
// 	width: 0%;
//   color: #000;
// 	top: 0;
// 	right: 0;
// 	z-index: 1050 !important;
// 	position: absolute;
// 	transition: 0.01s ease-out;

// 	.fliter-model-container {
// 		position: absolute;
// 		display: flex;
// 		flex-direction: column;
// 		top: 0;
// 		right: 0;
// 		bottom: 0;
// 		max-width: 100%;
// 		margin: 0;
// 		position: absolute;
//     background: #fff;
// 		// z-index: 1000 !important;
// 		transform: translateX(100%);
// 		transition: 0.5s ease-out;

//     .fliter-model-header{
//       border-bottom: 1px solid #ede2e2;

//       .fliter-model-header-title{
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding: 15px 20px;
//       }
//     }

//     .filter-model-content{
//       padding: 20px 20px;
//       background: #fff;
//     }

//     .filter-model-footer{
//       border-top: 1px solid #ede2e2;
//       padding: 15px 10px;
//     }
// 	}
// }

// .filter-model.show {
// 	width: 100%;

// 	.fliter-model-container {
// 		transform: translateX(0);
// 	}
// }

.filter-model {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 350px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 20px 20px;
  border: 1px solid #d7d1d1;
  border-radius: 5px;
  transform: translateX(-100%);
  left: 100%;
  top: 40px;

  .filter-model-content {
    padding-bottom: 20px;
  }

  .filter-model-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #d7d1d1;
  }
}

.filter-model.show {
  display: block;
}

.remind-model {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 500px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 20px 20px;
  margin: auto;
  border: 1px solid #d7d1d1;
  border-radius: 5px;
  transform: translateX(-100%);
  left: 50%;
  top: 40px;

  .remind-model-content {
    padding-bottom: 20px;
  }

  .remind-model-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #d7d1d1;
  }
}

.remind-model.show {
  display: block;
}

//filter search bar
.filter-searchBar-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .filter-searchBar-item {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    background: #ddedf5;

    .value-search {
      padding-left: 5px;
      padding-right: 5px;
      word-wrap: break-word;
    }

    .filter-searchBar-close {
      color: #008fd7;
      padding-left: 5px;
      cursor: pointer;
    }
  }
}

//css hover row table MUI
.mui-table-row {
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
// border table
.debtProductList {
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    .rt-tr.-even {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    }
    .rt-tr.-odd {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    }
  }
  .subComponent {
    .ReactTable .rt-tbody .rt-tr-group {
      border-bottom: none;
    }
  }
}

// fix position xem chi tiết hóa đơn
.carousel .slide .legend {
  transform: translateX(-50%);
}
.text-hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
.carousel .thumbs-wrapper {
  margin-bottom: 0 !important;
  ul{
    margin-bottom: 0;
  }
}

//reponsive ui ptsx
.detail-mobile {
  @media only screen and (max-width: 600px) {
    &-create {
      padding: 0 !important;
      margin: 0 !important;
    }

    .mobile-p-3 {
      padding: 16px;
    }

    .mobile-px-3 {
      padding: 0 16px;
    }

    .row {
      margin: 0 !important;
      padding-top: 16px;

      .mobile-col-6 {
        padding-bottom: 16px;
      }

      .col-12 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      .col-6 {
        &:nth-child(n) {
          padding-right: 8px !important;
          padding-left: 0 !important;
        }
        &:nth-child(2n) {
          padding-left: 8px !important;
          padding-right: 0 !important;
        }
      }

      .col-4 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      .col-3 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .login-container {
    .card {
      border-radius: 0 !important;
      margin-bottom: 0 !important;
    }

    .img-login {
      border-radius: 0 !important;
    }
  }
}

// .orange-bling-text {
//   @include bling(#ff0000, #f5baba, #fd9a9a);
// }

// .orange-bling1-text {
//   color: #ff0000;
//   border: solid 1px #ff0000;
//   animation: blingbling 2s infinite;
//   -webkit-animation: blingbling 2s infinite;
//   @keyframes blingbling {
//     0% {
//       opacity: 1;
//     }
//     10% {
//       opacity: 0.8;
//     }
//     20% {
//       opacity: 0.6;
//     }
//     30% {
//       opacity: 0.4;
//     }
//     40% {
//       opacity: 0.2;
//     }

//     60% {
//       opacity: 0.2;
//     }
//     70% {
//       opacity: 0.4;
//     }
//     80% {
//       opacity: 0.6;
//     }
//     90% {
//       opacity: 0.8;
//     }
//     100% {
//       opacity: 1;
//     }
//   }
// }
// trackingCustomer
.cell-table-highlight-late {
  // color: red !important;
  animation: bling_bling 1s infinite;
}
.cell-table-highlight-nearly {
  color: orange !important;
  animation: bling_bling1 1s infinite;
}
@keyframes bling_bling {
  0% {
    color: red;
  }
  100% {
    color: rgb(255, 110, 110);
  }
}
@keyframes bling_bling1 {
  0% {
    color: orange;
  }
  100% {
    color: rgb(255, 210, 126);
  }
}
// @keyframes bling_bling {
//   0% {
//     opacity: 1;
//   }
//   10% {
//     opacity: 0.8;
//   }
//   20% {
//     opacity: 0.6;
//   }
//   30% {
//     opacity: 0.4;
//   }
//   70% {
//     opacity: 0.4;
//   }
//   80% {
//     opacity: 0.6;
//   }
//   90% {
//     opacity: 0.8;
//   }
//   100% {
//     opacity: 1;
//   }
// }
.zoom {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: white;
  .ReactTable {
    height: 100vh !important;
  }
}
.trackingCustomer {
  .ReactTable {
    border: initial;
    // background-color: #393a3d !important;
    .rt-thead .rt-tr {
      padding-right: 0;
    }
    .rt-thead.-header .rt-tr .rt-th {
      // padding: 0;
      // background-color: #7ea0e1 !important ;
      background-color: #000000 !important ;
      color: white !important;
      border-right: 1px solid rgb(199, 199, 199);
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: rgb(199, 199, 199);
    }
    .rt-resizable-header-content {
      font-size: 20px !important;
    }
    .rt-tbody {
      &::-webkit-scrollbar {
        width: 0px;
        right: 0px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: white;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ffffff;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
      }
    }
    .rt-tbody .rt-td {
      height: 100px;
      text-wrap: wrap;
      justify-content: center;
      font-size: 35px !important;
      border-right: 1px solid rgb(199, 199, 199);
      border-right-width: 1px;
      color: white;
      border-right-style: solid;
      border-right-color: rgb(199, 199, 199);
      background-color: #393a3d !important;
    }
    .rt-thead .rt-td {
      border-right: 1px solid rgb(199, 199, 199);
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: rgb(199, 199, 199);
    }
    .rt-tbody .rt-tr-group {
      border-bottom: solid 1px rgb(199, 199, 199);
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgb(199, 199, 199);
    }
    .rt-tr.-odd {
      background: initial;
    }
    .rt-tbody {
      border-top: solid 1px rgb(199, 199, 199);
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgb(199, 199, 199);
    }
  }
}
.inventory-report {
  .ReactTable .rt-tfoot .rt-tr .rt-td {
    display: flex;
    justify-content: center;
    
    &:nth-child(-n + 3){
      border-right: 0px;
    }
  }
}
.productionProcess{
  .rt-tr-group,.rt-tr.-odd,.rt-tr.-even{
    min-height: 50px;
  }
  .rt-expandable {
    min-width: 50px;
    justify-content: center;
    align-items: center;
  }
  // .rt-thead.-header {
  //   .rt-tr {
  //     .rt-th:nth-child(1) {
  //      width: 50px !important;
  //       max-width: 50px !important;
  //     }
  //   }
  // }
}
.disabled_button {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.privacy-policy {

  text-align: justify;
  color: #000;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

  h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
  }
  
  h3 {
    font-size: 24px;
    line-height: 48px;
    font-weight: 700;
  }

  h1, h2, h3, h4, h5, h6, li, p {
    margin: 0 0 16px;
  }
}

.tools-report-receipt {
  display: flex;
  flex-direction: column;
  background: '#e6e8ec';
  height: '100%';
  gap: 12px;

  .title {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 8px;
  }

 .content-wrapper {
  position: relative;
  
  .img-arrows {
    width: 40px;
    height: 40px;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 }
}

//css unit-price
.unit-price {
  .info-service-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 550px) {
      flex-direction:  column;
    }
    
    &-title { 
      display: flex;
      align-items: end;
    }
  }

  .info-contact {
    color: "#000";
    font-size: "14px";
    align-items: "center";
    display: flex;
    height: 100%;
    flex-direction: column;
    font-size: 14px;

    &-wrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
   
    &-title {
      font-size: 14px;
      color: #000;
      font-weight: 500;
      margin-right: 8px;
    }
  }

  .product-list {
    position: relative;
    .group-button { 
      position: absolute;
      bottom: 6px;
      right: 0;
    }
  }

  .labelColor {
    margin-bottom: 8px;
  }
}

.tools-sync-data-misa {
  display: flex;
  flex-direction: column;
  height: '100%';
  gap: 12px;

 .toast-wrapper {
  margin-top: 24px;
  background: '#add8e6' !important;
  border-radius: 12px;
  padding: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
 }
}
.packingTextFieldPlaceholder {
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: black;
    opacity: 1;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: black;
    opacity: 1;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: black;
    opacity: 1;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: black;
    opacity: 1;
  }
}

.headerFistColumn {
  position: relative;
  width: 100px;
  height: 48px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 141.42%; 
    height: 1px;
    background-color: white;
    transform: rotate(26.57deg);;
    transform-origin: 0 0;
  }

  .headerFistColumn_text {
    position: absolute;
    font-size: 14px;
    font-weight: 600;
  }
  
  .headerFistColumn_month {
    bottom: 0;
    left: 0;
  }
  
  .headerFistColumn_day {
    top: 0;
    right: 16px;
  }
}

